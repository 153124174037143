import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, InputAdornment, Divider, TextField, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';


class HbcQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);

  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(2)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(2)
    });
  }
  render() {
    return (
      <>
        <h1 style={{textAlign: "center"}}>Blunt Trauma (Hit By Car)</h1>
        <h2 style={{textAlign: "center"}}>Clinical Quick Sheet</h2>
        <Grid container spacing={0} style={{textAlign: "left", maxWidth: "1200px", margin: "auto"}}>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>
            <Grid item xs={12}>
            <Typography><span style={{fontWeight: '700'}}>Presentation:</span> Often present after known blunt trauma such as hit by car or fall from significant height.</Typography>

            <Typography><span style={{fontWeight: '700'}}>Key points:</span></Typography>
            <ul>
            <li>Assess Airway, Breathing, Circulation, and major Disabilities that will dramatically change expectations or outcome.
            </li>
            <li>Disabilities that will significantly impact the prognosis should be quickly and tactfully brought to the pet parent's attention as you discuss goals and help guide them forward.
            </li>
            <li>Less immediately obvious major problems to rule out include: <span style={{fontWeight: '700'}}>pneumothorax, diaphragmatic hernia, pelvic fractures, urinary bladder rupture, hemoabdomen, bowel rupture</span>
            </li>
            </ul>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Provide flow by oxygen</span>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="label"
            inputProps={{ 'aria-label': 'label' }}
            /><span style={{fontWeight: '700'}}>Consider pain medication</span>
          </Grid>


          <Grid item xs={12}>
          <ul>
          <li>
          Consider Methadone at 0.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL</span> of 10 mg/mL Methadone IM or IV for this patient.
          </li>
          <li>
          Consider Fentanly at 3 - 6 mcg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(3)/50).toFixed(2)} mL to {(this.state.weightkg*(6)/50).toFixed(2)} mL</span> of 50 mcg/mL Fentanyl IM or IV for this patient.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="IV catheter"
            inputProps={{ 'aria-label': 'IV catheter' }}
            /><span style={{fontWeight: '700'}}>Place IV catheter</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          Consider pulling blood for PCV/TS or other point of care blood work at IV catheter placement
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              color="primary"
              /><span style={{fontWeight: '700'}}>Restore circulating volume</span>
            </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Consider an IV fluid bolus, 10 mL/kg (cats, low dose for dogs) to 20 mL/kg (high dose for dogs) = {(this.state.weightkg*(10)).toFixed(0)} mL to {(this.state.weightkg*(20)).toFixed(0)} mL over 20 minutes. Reassess and repeat if needed, and consider blood products for resuscitation.
            </li>
            <li>
            Consider low volume resuscitation with hypertonic saline, especially in cases with concern for concurrent head trauma. 4mL/kg = {(this.state.weightkg*(4)).toFixed(0)} mL of 7.2% NaCl.
            </li>
            </ul>
            </Grid>


          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            color="primary"
            label="POCUS"
            inputProps={{ 'aria-label': 'Anesthesia' }}
            /><span style={{fontWeight: '700'}}>POCUS</span>
          </Grid>

          <Grid item xs={12}>
          <ul>
          <li>
          If free peritoneal fluid, consider more caution with IV fluid boluses which may disrupt a blood clot.
          </li>
          <li>
          Consider serial abdominal POCUS evaluation to help rule out intra-abdominal bleed or uroabdomen. Remember POCUS alone is not adequate to diagnose bladder rupture, use fluid cytology and consider radiographs with contrast.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Steroids are usually contraindicated. Do NOT give steroids.
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Do not give NSAIDs during initial assessment or to patients with poor renal perfusion (in shock, possible blood loss).
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
          <Checkbox
          indeterminate
          color="default"
          />Early antibiotic therapy is usually only indicated if open fractures or concern for sepsis (unlikely in most cases).
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="12-24 h monitoring"
            inputProps={{ 'aria-label': '12-24 h monitoring' }}
            /><span style={{fontWeight: '700'}}>Consider 12-24 hours of careful monitoring and continuous reassessment</span>
          </Grid>
          <Grid item xs={12}>

          <Typography><span style={{fontWeight: '700'}}>Parameters that support discharge for at home monitoring:</span></Typography>
            <ul>
            <li>Good perfusion with pink mucous membranes and stable, patient-appropriate PCV/TS
            </li>
            <li>Systolic BP of &ge;100 mmHg
            </li>
            <li>Normal breathing rate and effort
            </li>
            <li>Ambulatory on 4 legs
            </li>
            <li>Unremarkable serial POCUS and radiographs
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications to consider:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Carprofen (Rimadyl) - DOGS</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth to decrease pain and inflammation. Give with food. Discontinue if decrease in appetite, vomiting, diarrhea, or dark tarry stool.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              2.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2.2)/(1)).toFixed(1)} mg</span> q12h
            </li>
            <li>
              4.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4.4)/(1)).toFixed(1)} mg</span> q24h
            </li>
            <li>
              Always round down your dose, can use 1/2 tablet increments
            </li>
            <li>
              Available as 25 mg, 75 mg, 100 mg
            </li>
            <li>
              Never combine with steroids or other NSAID drugs
            </li>
            <li>
              Also available as 50 mg/mL injectable solution: consider 2.2 to 4.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2.2)/(50)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4.4)/(50)).toFixed(2)} mL</span> of 50 mg/mL injectable carprofen SC as first treatment.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Meloxicam - DOGS</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 24 hours to decrease pain and inflammation. Give with food. Discontinue if decrease in appetite, vomiting, diarrhea, or dark tarry stool.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              0.1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)).toFixed(2)} mg</span> or <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(1.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>1.5 mg/mL</span> oral solution q24h.
            </li>
            <li>
              0.1 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)).toFixed(2)} mg</span> or <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(0.5)).toFixed(2)} mL</span> of <span style={{backgroundColor: '#ff726f'}}>0.5 mg/mL</span> oral solution q24h.
            </li>
            <li>
              Always round down your dose
            </li>
            <li>
              Most commonly available as 1.5 mg/mL oral suspension
            </li>
            <li>
              Never combine with steroids or other NSAID drugs
            </li>
            <li>
              Also available as 5 mg/mL injectable solution: consider 0.1 to 0.2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.1)/(5)).toFixed(2)} mL</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(0.2)/(5)).toFixed(2)} mL</span> of 5 mg/mL injectable meloxicam SC as first treatment.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Gabapentin</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 8-12 hours.  This is a pain medication. May cause sedation and help promote rest and recovery.  Decrease dose if too much sedation.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              5-10 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(5)/(1)).toFixed(1)} mg</span> to <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(10)/(1)).toFixed(1)} mg</span> q8-12h
            </li>
            <li>
              Available most commonly as 100 or 300 mg capsules or 50 mg/mL oral solution
            </li>
            <li>
              Can round up dose and/or increase depending on patient response
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Onsior (robenacoxib) - CATS</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give by mouth every 24 hours for 3 days.  This is an NSAID. Do not cobmine with other NSAIDs or steroids.
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>
            <ul>
            <li>
              Give one 6 mg tablet to cats weighing 2.5 to 6 kg; give two 6 mg tablets to cats weighing 6.1 to 12 kg
            </li>
            <li>
              If injection given, Start 24 hours after.
            </li>
            </ul>
            </Grid>


            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>


            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
              Discharge Note (Key Points):
            </Grid>
            <Grid item xs={12}>
              <ul>
              <li>
                Recheck immediately if any concern or new sign of illness.
              </li>
              <li>
                Signs of head trauma may include wobbliness or seizures.
              </li>
              <li>
                Internal bleeding, ruptured urinary bladder, lung bruising (pulmonary contusions), collapsed lung (pneumothorax), diaphragmatic hernia are all possible following significant trauma and may present themselves 1-3 days after the event even if not immediately obvious. Monitor for normal behavior, appetite, breathing, and urination.
              </li>
              </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
            <p>
              This guideline reflects opinion and experience that is not necessarily applicable to all institutions, situations, or patients.  It is intended as a reference for veterinarians caring for patients, but is not intended to replace their clinical judgement.
            </p>
            <p>
              All calculators are meant to double check your math. They are not a substitute for calculating your patient's dose. Always double check drug dosages and concentrations.
            </p>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
            <IconButton
              component={Link}
              size="large"
              color="primary"
              aria-label="back"
              aria-controls="back"
              aria-haspopup="false"
              to="/quick-sheets"
            >
              <ArrowBack />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }


};

export default HbcQS;
